import React, { useState, useEffect, useRef } from 'react';
import RocketCoefficientField from './RocketCoefficientField';
import Rocket from './Rocket';
import ConsoleField from './ConsoleField';
import io from 'socket.io-client';
import CashOutButton from './CashOutButton';
import cx from 'classnames';

const socket = io.connect('https://number.ethiochewata.com/');

const CrazyRocket = () => {
	const [game, setGame] = useState('');
	const play = useRef(false);
	const [odd, setOdd] = useState({ time: '1' });
	const cashArray = useRef([]);
	const [betAmount, setBetAmount] = useState(5);
	const [betState, setBetState] = useState('onBet');
	const [status, setStatus] = useState();
	const [lastGameResult, setLastGameResult] = useState('');

	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${'accessToken'}=`);
	const accessToken = parts.pop().split(';').shift();

	useEffect(() => {
		socket.emit('user', { accessToken: accessToken });
	}, []);

	useEffect(() => {
		socket.on('game', (data) => {
			setGame(data);
		});

		socket.on('play', (data) => {
			play.current = data;
			if (!play.current.play) setBetState('waiting');
			if (play.current.play) setBetState('onBet');
		});

		socket.on('time', (data) => {
			setOdd(data);
		});

		socket.on('possible_cash_out', (data) => {
			cashArray.current = data;
		});

		socket.on('status', (data) => {
			setStatus(data.status);
		});

		socket.on('last_time_odd', (data) => {
			setLastGameResult(data.letestOdd);
		});
	}, [socket]);

	const betHandler = () => {
		socket.emit('join_game', {
			betAmount: betAmount,
			accessToken: accessToken,
		});
	};

	const cashOutHandler = (cashOutId) => {
		socket.emit('cash_out_request', {
			betAmount: betAmount,
			cash_out_number: cashOutId,
			game_id: game.game_id,
			time: Date.now(),
			accessToken: accessToken,
		});
	};

	return (
		<div
			className={cx('crazy-rocket', {
				'start background-fast': status === 'start',
				'end background-fast': status === 'end',
			})}
			style={{ transform: 'scale(1); --animationTime: 0s' }}
		>
			<div className='container'>
				<h3 className='crazy-rocket__count-down'>
					{game?.count_down > 0 ? game?.count_down : 'ON PLAY'}
				</h3>

				<div className='wrapper'>
					{odd.time > 1 && <RocketCoefficientField odd={odd.time} />}
					{lastGameResult && <RocketCoefficientField odd={lastGameResult} />}
					<div className='console-background--z1'></div>
					<div className='console-background--z2'></div>
					<Rocket />
					<ConsoleField
						betAmount={betAmount}
						setBetAmount={setBetAmount}
						betHandler={betHandler}
						betState={betState}
					/>
				</div>
				<div className='cashout-btns'>
					{cashArray &&
						cashArray.current.map((cash, i) => (
							<CashOutButton
								key={i}
								odd={odd?.time}
								cashOutHandler={cashOutHandler}
								cashOut={cash}
							/>
						))}
				</div>
			</div>
		</div>
	);
};

export default CrazyRocket;
