import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ProfileSideBar from './ProfileSideBar';
import HelmetSeo from '../../components/HelmetSeo';
import useAuth from '../../hooks/useAuth';

const Profile = () => {
	const [active, setActive] = useState('setting');
	const user = useAuth();

	return (
		<>
			<HelmetSeo
				title={`Profile: ${user.name}`}
				description={`Profile: ${user.name}`}
				link={`/profile`}
			/>
			<div className='user-view'>
				<ProfileSideBar active={active} setActive={setActive} />
				<Outlet />
			</div>
		</>
	);
};

export default Profile;
