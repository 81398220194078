import React from 'react';
import cx from 'classnames';
import _ from 'lodash';

const Pagination = ({ itemsCount, pageSize, onPageChange, currentPage }) => {
	const pagesCount = Math.ceil(itemsCount / pageSize);

	if (pagesCount === 1) return null;

	const pages = _.range(1, pagesCount + 1);

	return (
		<ul className='pagination pagination-lg '>
			{pages.map((page) => (
				<li
					key={page}
					className={cx({ active: page === currentPage }, 'page-item')}
					aria-current='page'
				>
					<button className='page-link' onClick={() => onPageChange(page)}>
						{page}
					</button>
				</li>
			))}
		</ul>
	);
};

export default Pagination;
