import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormField, SubmitButton, ErrorMessage } from '../../components/form';
import { useLoginMutation } from './authApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import GoogleLogin from './GoogleLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelmetSeo from '../../components/HelmetSeo';
import { faSignIn } from '@fortawesome/free-solid-svg-icons';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email not valid').required('Email is required'),
	password: Yup.string()
		.min(8, 'The length of your password must be 8 chracters long.')
		.required('Password is required'),
});

const LoginForm = () => {
	const navigate = useNavigate();
	const [login, { isLoading, isSuccess, isError, error }] = useLoginMutation();

	const methods = useForm({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = async (values) => {
		await login(values);
	};

	if (isSuccess) navigate('/profile');

	// console.log('Error', isError, );
	return (
		<>
			<HelmetSeo
				title='Login: Sign in to your account'
				description='Sign in to your account'
				link='/login'
			/>
			<div className='auth'>
				<div className='auth__container'>
					<div className='auth__icon'>
						<FontAwesomeIcon icon={faSignIn} fontSize={30} />
					</div>
					<div className='auth__form'>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)} className='form'>
								<FormField
									name='email'
									label='Email'
									type='email'
									placeholder='Email'
								/>
								<FormField
									name='password'
									label='Password'
									type='password'
									placeholder='Password'
								/>
								<ErrorMessage visible={isError} error={error?.data?.message} />
								<div className='form__group'>
									<GoogleLogin />
								</div>

								<SubmitButton
									disable={isLoading}
									title={isLoading ? <PulseLoader color={'#fff'} /> : 'Sign In'}
								/>

								<div className='login__divider' />
								<div className='action-links'>
									<Link to='/signup' className='btn-text'>
										Don't have an account? Sign up
									</Link>
									<Link to='/forgot-password' className='btn-text'>
										Forgot password?
									</Link>
								</div>
							</form>
						</FormProvider>
					</div>
				</div>
			</div>
		</>
	);
};

export default LoginForm;
