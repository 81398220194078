import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

// Input: columns: array, sortColumn: object
// Output: raise onSort event

const TableHeader = ({ columns, onSort, sortColumn }) => {
	const raiseSort = (path) => {
		if (sortColumn.path === path)
			sortColumn.order = sortColumn.order === 'asc' ? 'desc' : 'asc';
		else {
			sortColumn.path = path;
			sortColumn.order = 'asc';
		}
		onSort(sortColumn);
	};

	const renderSortIcon = (column) => {
		if (column?.path !== sortColumn?.path) return null;
		if (sortColumn?.order === 'asc')
			return <FontAwesomeIcon icon={faSortUp} className='sortIcon' />;
		return <FontAwesomeIcon className='sortIcon' icon={faSortDown} />;
	};

	return (
		<thead className='table-dark '>
			<tr className='align-middle'>
				{columns?.map((column) => (
					<th
						className='clickable align-middle p-3'
						key={column.path || column.key}
						onClick={() => raiseSort(column.path)}
						scope='col'
					>
						{column.label}
						{renderSortIcon(column)}
					</th>
				))}
			</tr>
		</thead>
	);
};

export default TableHeader;
