import { apiSlice } from '../../../app/api/apiSlice';
import { setProfile } from '../../profile/profileSlice';

export const gameApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		createGame: builder.mutation({
			query: (game) => ({
				url: '/game/play',
				method: 'POST',
				body: {},
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const result = await queryFulfilled;
					dispatch(setProfile(result.data.user));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		getNextGame: builder.mutation({
			query: (game) => ({
				url: '/game/answer',
				method: 'POST',
				body: { ...game },
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const result = await queryFulfilled;
					if (result.data.user) dispatch(setProfile(result.data.user));
				} catch (err) {
					console.log(err);
				}
			},
		}),
	}),
});

export const { useCreateGameMutation, useGetNextGameMutation } = gameApiSlice;
