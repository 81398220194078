import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PulseLoader from 'react-spinners/PulseLoader';
import { useForgotPasswordMutation } from './authApiSlice';

import { FormProvider } from 'react-hook-form';
import { FormField, SubmitButton } from '../../components/form';
import { toast } from 'react-toastify';
import HelmetSeo from '../../components/HelmetSeo';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email not valid').required('Email is required'),
});

const ForgotPassword = () => {
	const [forgotPassword, { isLoading, isSuccess }] =
		useForgotPasswordMutation();

	const methods = useForm({
		resolver: yupResolver(validationSchema),
	});

	const navigate = useNavigate();

	const onSubmit = async (values) => {
		await forgotPassword(values?.email);
	};

	if (isSuccess) {
		toast.success(
			'Password reset email sent to your email, Please check your email!'
		);
		navigate('/');
	}

	if (isLoading)
		return (
			<div
				style={{
					height: '70vh',
					width: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<PulseLoader color={'#55c57a'} />
			</div>
		);

	return (
		<>
			<HelmetSeo
				title='Forgot Password'
				description='Forgot Password Page'
				link='/forgot-password'
			/>
			<div className='auth'>
				<div className='login-form u_margin_top_big'>
					<h1
						className='heading-tertiary u_center_text u_margin_bottom_medium'
						style={{ fontSize: '2.5rem' }}
					>
						Forgot Password
					</h1>
					<FormProvider {...methods}>
						<form
							className='login__form'
							onSubmit={methods.handleSubmit(onSubmit)}
						>
							<FormField
								name='email'
								type='email'
								label='Email'
								placeholder='Enter Your Email'
							/>

							<SubmitButton title='Submit' />
						</form>
					</FormProvider>
				</div>
			</div>
		</>
	);
};

export default ForgotPassword;
