import { apiSlice } from '../../app/api/apiSlice';
import { setProfile } from '../profile/profileSlice';

export const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: '/users/login',
				method: 'POST',
				body: { ...credentials },
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const result = await queryFulfilled;
					dispatch(setProfile(result.data.user));
				} catch (err) {
					console.log(err);
				}
			},
		}),
		signInWithGoogle: builder.mutation({
			query: (credentials) => ({
				url: '/users/google-signin',
				method: 'POST',
				body: { ...credentials },
			}),
		}),
		signup: builder.mutation({
			query: (credentials) => ({
				url: '/users/signup',
				method: 'POST',
				body: { ...credentials },
			}),
		}),
		verifyOtp: builder.mutation({
			query: (credentials) => ({
				url: '/users/verify-otp',
				method: 'POST',
				body: { ...credentials },
			}),
		}),
		resendOtp: builder.mutation({
			query: (credentials) => ({
				url: '/users/resend-otp',
				method: 'POST',
				body: { ...credentials },
			}),
		}),
		forgotPassword: builder.mutation({
			query: (email) => ({
				url: '/users/forgot-password',
				method: 'POST',
				body: { email },
			}),
		}),
		resetPassword: builder.mutation({
			query: (credentials) => ({
				url: `users/reset-password/${credentials.resetToken}`,
				method: 'PATCH',
				body: { password: credentials.password },
			}),
		}),
		updatePassword: builder.mutation({
			query: (credentials) => ({
				url: 'users/update-my-password',
				method: 'PATCH',
				body: { ...credentials },
			}),
		}),
		updatePhoneNumber: builder.mutation({
			query: (credentials) => ({
				url: 'users/update-my-phone-number',
				method: 'PATCH',
				body: { ...credentials },
			}),
		}),
		updateMe: builder.mutation({
			query: (body) => ({
				url: 'users/update-me',
				method: 'PATCH',
				body,
			}),
		}),
		sendLogout: builder.mutation({
			query: () => ({
				url: '/users/logout',
				method: 'DELETE',
			}),
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					await queryFulfilled;
					window.location.replace('/login');

					setTimeout(() => {
						dispatch(apiSlice.util.resetApiState());
					}, 1000);
				} catch (err) {
					console.log(err);
				}
			},
		}),
		getMe: builder.mutation({
			query: () => ({
				url: '/users/me',
				method: 'GET',
			}),

			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				try {
					const result = await queryFulfilled;
					dispatch(setProfile(result.data.user));
				} catch (err) {
					console.log(err);
				}
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useSignupMutation,
	useVerifyOtpMutation,
	useSendLogoutMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
	useUpdatePasswordMutation,
	useSignInWithGoogleMutation,
	useUpdatePhoneNumberMutation,
	useUpdateMeMutation,
	useGetMeMutation,
	useResendOtpMutation,
} = authApiSlice;
