import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api/apiSlice';
import profileReducer from '../features/profile/profileSlice';
import { setupListeners } from '@reduxjs/toolkit/query';

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		profile: profileReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(apiSlice.middleware),
	devTools: false,
});

setupListeners(store.dispatch);
