import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const Table = ({ columns, onSort, sortColumn, data }) => {
	return (
		<table className='table table-light table-striped table-hover align-middle'>
			<TableHeader columns={columns} onSort={onSort} sortColumn={sortColumn} />
			<TableBody data={data} columns={columns} />
		</table>
	);
};

Table.propTypes = {
	columns: PropTypes.array.isRequired,
	onSort: PropTypes.func.isRequired,
	sortColumn: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
};

export default Table;
