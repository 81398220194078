import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import { FormField, SubmitButton } from '../../components/form';

import { useSignupMutation } from './authApiSlice';
import { Link, useNavigate } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { toast } from 'react-toastify';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HelmetSeo from '../../components/HelmetSeo';
import CountryCode from '../../components/form/CountryCode';

const phoneRegExp = /^(9|7)?\d{8}$/;

const validationSchema = Yup.object().shape({
	name: Yup.string().min(4).required('Name is required'),
	email: Yup.string().email('Email not valid').required('Email is required'),
	phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
	password: Yup.string()
		.min(8, 'The length of your password must be 8 chracters long.')
		.required('Password is required'),
	passwordConfirm: Yup.string().oneOf(
		[Yup.ref('password'), null],
		'Passwords must match'
	),
});

const SignUpForm = () => {
	const [signup, { isLoading, isSuccess }] = useSignupMutation();
	const [phone, setPhone] = useState('+251');

	const methods = useForm({
		resolver: yupResolver(validationSchema),
	});

	const navigate = useNavigate();

	const onSubmit = async (values) => {
		values.passwordConfirm = undefined;
		setPhone(values.phone);
		await signup(values);
	};

	useEffect(() => {
		if (isSuccess) {
			toast.success('OTP sent to your phone number and email');
			navigate(`/verify-otp/${phone}`);
		}
	}, [isSuccess, navigate, phone]);

	return (
		<>
			<HelmetSeo
				title='Sign Up: Create your account'
				description={`Sign up to create your account`}
				link='/signup'
			/>

			<div className='auth'>
				<div className='auth__container'>
					<div className='auth__icon'>
						<FontAwesomeIcon icon={faUser} fontSize={30} />
					</div>

					<div className='auth__form'>
						<FormProvider {...methods}>
							<form onSubmit={methods.handleSubmit(onSubmit)} className='form'>
								<div className='row' style={{ marginBottom: '-1rem' }}>
									<div className='col-1-of-2'>
										<FormField
											label='Name'
											type='text'
											name='name'
											placeholder='Name'
										/>
									</div>
									<div className='col-1-of-2'>
										<div
											style={{
												display: 'flex',
												gap: '0px',
												alignItems: 'center',
												justifyContent: 'flex-start',
											}}
										>
											<CountryCode />
											<FormField
												label='Phone'
												type='text'
												name='phone'
												placeholder='Phone number'
												size='9'
											/>
										</div>
									</div>
								</div>
								<div className='row' style={{ marginBottom: '-1rem' }}>
									<div className='col-1-of-2'>
										<FormField
											label='Email'
											type='email'
											name='email'
											placeholder='Email'
										/>
									</div>
									<div className='col-1-of-2'>
										<FormField
											label='Password'
											type='password'
											name='password'
											placeholder='Password'
										/>
									</div>
								</div>
								<div className='row' style={{ marginBottom: '-1rem' }}>
									<div className='col-1-of-2'>
										<FormField
											label='Confirm Password'
											type='password'
											name='passwordConfirm'
											placeholder='Confirm Password'
										/>
									</div>
								</div>
								<div className='row' style={{ marginBottom: '1rem' }}>
									<div className='col-1-of-2'>
										<SubmitButton
											title={
												isLoading ? <PulseLoader color={'#fff'} /> : 'Sign Up'
											}
										/>
									</div>
								</div>
								<div className='login__divider' />
								<div className='action-links'>
									<Link to='/login' className='btn-text'>
										Already registered? Sign In
									</Link>
									<Link to='/forgot-password' className='btn-text'>
										Forgot password?
									</Link>
								</div>
							</form>
						</FormProvider>
					</div>
				</div>
			</div>
		</>
	);
};

export default SignUpForm;
