import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBriefcase,
	faCreditCard,
	faGear,
	faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { useSendLogoutMutation } from '../authentication/authApiSlice';
import Cookies from 'js-cookie';
import PulseLoader from 'react-spinners/PulseLoader';

function ProfileSideBar({ active, setActive }) {
	const [sendLogout, { isLoading }] = useSendLogoutMutation();

	const handleLogout = async () => {
		await sendLogout();
		Cookies.remove('accessToken');
	};
	return (
		<nav className='user-view__menu'>
			<ul className='side-nav'>
				<li>
					<Link
						to='/profile'
						onClick={() => setActive('setting')}
						className={cx({ 'side-nav--active': active === 'setting' })}
					>
						<FontAwesomeIcon icon={faGear} />
						Settings
					</Link>
				</li>
				<li>
					<Link
						to='/profile/my-games'
						onClick={() => setActive('my-games')}
						className={cx({ 'side-nav--active': active === 'my-games' })}
					>
						<FontAwesomeIcon icon={faBriefcase} />
						My Games
					</Link>
				</li>

				<li>
					<Link
						to='/profile/balance'
						onClick={() => setActive('balance')}
						className={cx({ 'side-nav--active': active === 'balance' })}
					>
						<FontAwesomeIcon icon={faCreditCard} />
						Balance
					</Link>
				</li>
				<li>
					<Link
						// to='/profile/balance'
						onClick={handleLogout}
					>
						<FontAwesomeIcon icon={faSignOut} />
						{isLoading ? <PulseLoader color={'#fff'} /> : 'Log Out'}
					</Link>
				</li>
			</ul>
		</nav>
	);
}

export default ProfileSideBar;
