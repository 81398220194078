import React, { useState } from 'react';
import avatar from '../../../assets/img/1.png';

const ConsoleField = ({ betAmount, setBetAmount, betHandler, betState }) => {
	const BetButton = ({ disabled = false, display }) => (
		<div className='console__bet'>
			<button
				onClick={betHandler}
				disabled={disabled}
				type='button'
				className='console__bet-button disable'
			>
				<span className='console__bet-button-qashout'>
					<span
						className='console__bet-button-qashout-top'
						style={{ display: 'block' }}
					>
						NaN
					</span>
					<span className='console__bet-button-qashout-bottom'></span>
				</span>
				<span className='console__bet-button-text'>{display}</span>
				<span className='lds-spinner'>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</span>
			</button>
			<span className='console__bet-button-layer'></span>
		</div>
	);

	return (
		<div className='console__field'>
			<div className='console console-first'>
				<div className='console__settings'>
					<div className='console-top'>
						<div className='console-top__next-round'>
							<span className='console-top__next-round-svg' />
						</div>
					</div>
					<div className='console__buttons'>
						<div className='console__buttons-top'>
							<label className='circle'>
								<input type='text' className='circle__input' value='5' />
								<span className='circle__letters-field'>
									<span className='circle__letters'>
										<span className='circle__letter'>
											<span
												style={{
													transform: 'rotate(1.5deg) translateY(-135px)',
												}}
											>
												{betAmount}
											</span>
										</span>
										<span className='circle__letter'>
											<span
												style={{
													transform: 'rotate(8.5deg) translateY(-135px)',
												}}
											></span>
										</span>
									</span>
								</span>
							</label>
							<button
								onClick={() => setBetAmount(betAmount - 1)}
								type='button'
								className='console__buttons-minus'
							>
								<span className='console__buttons-tooltip'>
									<span className='console__buttons-tooltip-inner'>Min 2</span>
								</span>
								<span className='console__buttons-minus-line'></span>
							</button>
							<button
								onClick={() => setBetAmount(betAmount + 1)}
								type='button'
								className='console__buttons-plus'
							>
								<span className='console__buttons-tooltip'>
									<span className='console__buttons-tooltip-inner'>
										Max 500
									</span>
								</span>
								<span className='console__buttons-minus-line'></span>
								<span className='console__buttons-minus-line console__button-plus-line'></span>
							</button>
							<div className='block-click'></div>
						</div>
						<div className='console__buttons-bottom'>
							<button
								onClick={() => setBetAmount(5)}
								type='button'
								className='odd-button odd-button-first-1'
							>
								<span
									className='odd-button-first1'
									style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
								>
									5
								</span>
							</button>
							<button
								onClick={() => setBetAmount(10)}
								type='button'
								className='odd-button odd-button-second-2'
							>
								<span
									className='odd-button-second1'
									style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
								>
									1
								</span>
								<span
									className='odd-button-second2'
									style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
								>
									0
								</span>
							</button>
							<button
								onClick={() => setBetAmount(25)}
								type='button'
								className='odd-button odd-button-third-2'
							>
								<span
									className='odd-button-third1'
									style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
								>
									2
								</span>
								<span
									className='odd-button-third2'
									style={{ marginRight: '1.5px', marginLeft: '1.5px' }}
								>
									5
								</span>
							</button>
						</div>
					</div>
					<div className='console__settings-left'>
						<div className='console-switchers'>
							<div className='console-switchers__auto active'>
								<div className='console-switchers-list'>
									<div className='console-switchers-list'>
										<div className='switch__title' style={{ fontSize: '14px' }}>
											Auto Bet
										</div>
										<label className='switch__label'>
											<input type='checkbox' className='switch__input' />
											<span className='switch__slider'></span>
										</label>
									</div>
									<div className='console-switchers-list'>
										<div
											className='switch__title'
											style={{ fontSize: '14px', marginLeft: '0px' }}
										>
											Auto Cashout
										</div>
										<label className='switch__label'>
											<input
												type='checkbox'
												className='switch__input switch__input-qashout'
												name='active'
											/>
											<span className='switch__slider'></span>
											<input
												autocomplete='off'
												type='text'
												name='odd'
												className='switch__price-input'
												value='5.00'
											/>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					{betState === 'onBet' && <BetButton display='BET' />}
					{betState === 'waiting' && (
						<BetButton disabled={true} display='WAIT' />
					)}

					<div className='console__settings-right'>
						<button className='add-ticket__button'></button>
						<div className='add-ticket__text'>
							<span
								className='add-ticket__text-add'
								style={{ fontSize: '14px' }}
							>
								Add Ticket
							</span>
							<span className='add-ticket__text-delete'>Delete</span>
						</div>
					</div>
				</div>
				<div className='console__notification active'></div>
			</div>
			<div className='console__bottom-field'>
				<div className='console__bottom'>
					<div className='popup-avatars__item selected console__avatar'>
						<div className='popup-avatars__photo'>
							<img alt='avatar' className='avatars-img' src={avatar} />
						</div>
					</div>
					<div className='console__bottom-id'>ID: 395802</div>
					<div className='console__bottom-price'>2.69 ETB</div>
					<div className='console__bottom-steps'>
						<div
							className='console__bottom-steps-active'
							style={{ width: '0.0179357%' }}
						></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsoleField;
