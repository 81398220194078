import React, { useEffect } from 'react';
import Table from '../../components/table/Table';

const TransactionsTable = ({ transactions, onSort, sortColumn }) => {
	const columns = [
		{
			path: '_id',
			label: 'Transaction Id',
		},
		{ path: 'amount', label: 'Amount' },
		{ path: 'status', label: 'Status' },
		{ path: 'type', label: 'Type' },
		{
			path: 'createdAt',
			label: 'Date',
			content: (transaction) =>
				new Date(transaction?.createdAt).toLocaleString(),
		},
	];

	useEffect(() => {
		console.log('transactions', transactions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Table
			columns={columns}
			data={transactions}
			sortColumn={sortColumn}
			onSort={onSort}
		/>
	);
};

export default TransactionsTable;
