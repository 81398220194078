import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

const useAuth = () => {
	const token = Cookies.get('accessToken');

	let isAdmin = false;

	if (token) {
		const decoded = jwtDecode(token);
		const { name, email, photo, phone, verified, role, balance, _id } = decoded;
		isAdmin = role === 'Admin';

		return {
			name,
			email,
			verified,
			photo,
			phone,
			role,
			_id,
			balance,
			isAdmin,
			isLoggedIn: true,
		};
	}

	return null;
};
export default useAuth;
