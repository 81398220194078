import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// import Login from './pages/Login';
// import SignUp from './pages/SignUp';
import SignUp from './features/authentication/SignUp';
import Login from './features/authentication/Login';
import VerifyOtp from './features/authentication/VerifyOtp';
import ForgotPassword from './features/authentication/ForgotPassword';
import Profile from './features/profile/Profile';

import ProtectedRoute from './features/authentication/ProtectedRoute';
import ResetPassword from './features/authentication/ResetPassword';
import RestrictUnverifiedUser from './features/authentication/RestrictUnverifiedUser';

import useAuth from './hooks/useAuth';
import Header from './layouts/Header';
import Public from './pages/Public';
import Game from './pages/games/Game';
import Play from './pages/games/play/play';
import Balance from './features/profile/Balance';
import MyGames from './features/profile/MyGames';
import ProfileSetting from './features/profile/ProfileSetting';
import { useGetMeMutation } from './features/authentication/authApiSlice';
function App() {
	const [getMe] = useGetMeMutation();

	const getProfile = async () => {
		await getMe();
	};

	useEffect(() => {
		getProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const user = useAuth();

	return (
		<>
			<ToastContainer
				position='top-center'
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme='dark'
			/>
			<Header />
			<Routes>
				<Route path='/' element={<Public />} />
				<Route
					path='/login'
					element={user?.isLoggedIn ? <Navigate to='/profile' /> : <Login />}
				/>

				<Route path='/signup' element={<SignUp />} />
				<Route path='/verify-otp/:phone' element={<VerifyOtp />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				<Route path='/resetPassword/:resetToken' element={<ResetPassword />} />

				<Route path='/games' element={<ProtectedRoute />}>
					<Route path='/games' element={<RestrictUnverifiedUser />}>
						<Route index element={<Game />} />
						<Route path=':gameId/play' element={<Play />} />
					</Route>
				</Route>

				<Route path='/profile' element={<ProtectedRoute />}>
					<Route path='/profile' element={<Profile />}>
						<Route index element={<ProfileSetting />} />
						<Route path='balance' element={<Balance />} />
						<Route path='my-games' element={<MyGames />} />
					</Route>
				</Route>
			</Routes>
		</>
	);
}

export default App;
