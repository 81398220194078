import React from 'react';
import './play.css';
import { useParams } from 'react-router-dom';
import ChoiceGame from '../../../features/Game/choiceGame';
import HelmetSeo from '../../../components/HelmetSeo';
import Rocket from '../rocket/rocket';
import CrazyRocket from '../crazyRocket/CrazyRocket';

export default function Play() {
	const { gameId } = useParams();

	return (
		<>
			<HelmetSeo
				title='Game: Play'
				description={'Play the game'}
				link={`/games/${gameId}/play`}
			/>

			<div className='play'>
				{gameId === '1' ? <ChoiceGame /> : null}
				{gameId === '2' ? <Rocket /> : null}
				{gameId === '3' ? <CrazyRocket /> : null}
				{gameId === '4' ? <h1>Game 4</h1> : null}
				{gameId === '5' ? <h1>Game 5</h1> : null}
				{gameId === '6' ? <h1>Game 6</h1> : null}
			</div>
		</>
	);
}
