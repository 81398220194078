import React from 'react';
import HelmetSeo from '../../components/HelmetSeo';

export default function GamePlay({
	playHandler,
	gameResult,
	playText,
	resulDisplay,
}) {
	let result;
	if (gameResult === 3) {
		result = (
			<div className='winner'>
				<h2 className={resulDisplay}>Game Result: {gameResult}/10</h2>
				<p>You won 1000 Birr</p>
			</div>
		);
	} else if (gameResult) {
		result = <h2 className={resulDisplay}>Game Result: {gameResult}/10</h2>;
	} else {
		result = <h2 className={resulDisplay}>Game Result: 0/10</h2>;
	}

	return (
		<>
			>
			<div className='play-container'>
				<h1>Quiz</h1>
				<p style={{ color: 'white' }}> {result}</p>

				<p style={{ color: 'white' }}>Win up to 10000 with football game</p>
				<div className='play-buttons'>
					<button className='play__button' onClick={playHandler}>
						{playText}
					</button>
					<button className='how-to-play'>How to Play</button>
				</div>
			</div>
		</>
	);
}
