import React from 'react';

const CashOutButton = ({ cashOut, cashOutHandler, odd, disabled = false }) => {
	const handler = (e) => {
		cashOutHandler(parseInt(e.target.id));
	};

	if (odd)
		return (
			<div className='cashout-btns__btn'>
				<button
					onClick={(e) => handler(e)}
					// value={cashOut.cashoutId}

					type='button'
					className='cashout-btns__btn disable'
				>
					{/* <span className='cashout-btns__btn-qashout'>
						<span
							className='cashout-btns__btn-qashout-top'
							style={{ display: 'block' }}
						>
							NaN
						</span>
						<span className='cashout-btns__btn-qashout-bottom'></span>
					</span> */}
					<span
						id={cashOut?.cashoutId}
						className='cashout-btns__btn-button-text'
					>
						{(cashOut?.betAmount * parseFloat(odd)).toFixed(2)}
					</span>
				</button>
			</div>
		);
};

export default CashOutButton;
