import React from 'react';

const RocketCoefficientField = ({ odd }) => {
	return (
		<div className='rocket__coefficient-field'>
			<div className='rocket__coefficient'>
				<span className='rocket__coefficient-background wcolor-2'></span>
				<span className='rocket__coefficient-count'>{odd}</span>
				<span className='rocket__coefficient-x'>x</span>
			</div>
		</div>
	);
};

export default RocketCoefficientField;
