import React from 'react';
import { Helmet } from 'react-helmet-async';

const HelmetSeo = ({ title, description, link, keywords }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			{keywords && <meta name='keywords' content={keywords} />}
			{link && <link rel='canonical' href={link} />}
		</Helmet>
	);
};

export default HelmetSeo;
