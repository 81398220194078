import React from 'react';

const ErrorMessage = ({ error, visible }) => {
	if (!visible || !error) return null;

	return (
		<p
			style={{
				bakgroundColor: '#3a3a3a',
				padding: '1rem',
				fontSize: '1.5rem',
				color: 'red',
			}}
		>
			{' '}
			{error}
		</p>
	);
};

export default ErrorMessage;
