import React from 'react';

const Rocket = ({ opacity = 0 }) => {
	return (
		<div className='rocket'>
			<div className='rocket__garage'></div>
			<div className='rocket__inner'></div>
		</div>
	);
};

export default Rocket;
