import { useGetNextGameMutation } from './GameApi/gameApiSlice';
import { useState } from 'react';

export default function ChoiceButton({
	choice,
	value,
	game,
	setChoice,
	setQuestion,
	setGameResult,
	setPlay,
	setCount,
	count,
	questionId,
	setQuestionId,
	setTimer,
	setTimerStyle,
	setButtonClick,
	buttonClick,
}) {
	const [getNextGame] = useGetNextGameMutation();
	const [buttonStyle, setButtonStyle] = useState('choice__button');
	const choiceHandler = async (e) => {
		if (buttonClick) {
			setButtonClick(false);
			setChoice([
				"    ",
				"    ",
				"    ",
				"    ",
			]);
			setTimer(200);
			setQuestion('        ');
			setTimerStyle('none');
			setButtonStyle('button_load');
		const answer = e.target.value;
		const game_id = game;
		setCount(count + 1);
		const { data: response } = await getNextGame({
			game_id,
			answer,
			questionId,
		});
		await setTimer(100);

		if (count >= 10) {
			setPlay(true);
			setQuestion('');
			setGameResult(response.gameResult);
			return '';
		}
		setTimerStyle('flex');
		setButtonClick(true);
		setButtonStyle('choice__button');
		setQuestionId(response.questionId);
		setQuestion(response.nextQuestion.question);
		setChoice([
			response.nextQuestion.option1,
			response.nextQuestion.option2,
			response.nextQuestion.option3,
			response.nextQuestion.option4,
		]);
		}
	};
	return (
		<div className='choice__buttons'>
			<button
				className={buttonStyle}
				value={`option${value + 1}`}
				onClick={choiceHandler}
			>
				{choice[value]}
			</button>
		</div>
	);
}
