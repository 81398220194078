import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import logoSrc from '../assets/img/game-logo1.png';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faBars } from '@fortawesome/free-solid-svg-icons';
import MobileNav from './MobileNav';
import { useSelector } from 'react-redux';

const Header = () => {
	const { profile } = useSelector((state) => state.profile);

	const user = useAuth();
	const [show, setShow] = React.useState(false);

	return (
		<>
			{show && <MobileNav setShow={setShow} show={show} profile={profile} />}

			<header className='header-wrapper'>
				<div className='header'>
					<div className='header-left'>
						<div className='logo'>
							<Link to='/games'>
								<img src={logoSrc} className='logo-img' alt='Natours logo' />
							</Link>
						</div>
					</div>
					<nav className='nav'>
						<ul className='nav--user'>
							<li>
								<Link to='/games' className='nav-el'>
									Games
								</Link>
							</li>
							{user?.isLoggedIn ? (
								<>
									<li>
										<Link
											to='/profile/balance'
											className='nav-el'
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												gap: '.5rem',
											}}
										>
											<span>
												<FontAwesomeIcon icon={faCreditCard} />
											</span>
											<span>
												{' '}
												Balance :{' '}
												<span style={{ color: 'orangered' }}>
													{profile?.balance.toFixed(2)}
												</span>
											</span>
										</Link>
									</li>

									<li>
										<Link
											to={user.isAdmin ? '/admin' : '/profile'}
											className='nav-el'
										>
											<img
												src={`${process.env.REACT_APP_PUBLIC_API_URL}/img/users/${user.photo}`}
												className='nav__user-img'
												alt={user.name}
											/>
										</Link>
									</li>
									<li>
										<NavLink to={'/profile'} className='nav-el'>
											{user.name?.split(' ')[0]}
										</NavLink>
									</li>
									{user.isAdmin && (
										<li>
											<NavLink to='/dashboard' className='nav-el'>
												Dashboard
											</NavLink>
										</li>
									)}
								</>
							) : (
								<>
									<li>
										<NavLink to='/login' className='nav-el'>
											Log IN
										</NavLink>
									</li>
									<li>
										<NavLink to='/signup' className='nav-el signup'>
											Sign Up
										</NavLink>
									</li>
								</>
							)}
						</ul>
					</nav>

					<div className='mobile-nav-auth-container'>
						{!user?.isLoggedIn ? (
							<ul className='mobile-nav-auth'>
								<li>
									<NavLink to='/login' className='nav-el'>
										Log IN
									</NavLink>
								</li>
								<li>
									<NavLink to='/signup' className='nav-el signup'>
										Sign Up
									</NavLink>
								</li>
							</ul>
						) : (
							<div>
								<Link
									to='/profile/balance'
									className='nav-el'
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										gap: '.5rem',
									}}
								>
									<span>
										<FontAwesomeIcon icon={faCreditCard} />
									</span>
									<span>
										{' '}
										Balance :{' '}
										<span style={{ color: 'orangered' }}>
											{profile?.balance.toFixed(2)}
										</span>
									</span>
								</Link>
							</div>
						)}
						<FontAwesomeIcon
							icon={faBars}
							className='hamburger'
							onClick={() => setShow(!show)}
						/>
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
