import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSendLogoutMutation } from '../features/authentication/authApiSlice';
import useAuth from '../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import PulseLoader from 'react-spinners/PulseLoader';

const MobileNav = ({ setShow, show }) => {
	const user = useAuth();
	const [sendLogout, { isLoading }] = useSendLogoutMutation();

	const handleLogout = async () => {
		await sendLogout();
		Cookies.remove('accessToken');
		setShow(false);
	};
	return (
		<>
			{show && (
				<div className='mobile-nav-wrapper'>
					<FontAwesomeIcon
						icon={faClose}
						className='close-icon'
						onClick={() => setShow(false)}
					/>
					<nav className='mobile-nav'>
						<ul className='nav--user'>
							{user?.isLoggedIn ? (
								<>
									<li>
										<Link
											to={user.isAdmin ? '/admin' : '/profile'}
											onClick={() => setShow(false)}
											className='nav-el'
										>
											<img
												src={`${process.env.REACT_APP_PUBLIC_API_URL}/img/users/${user.photo}`}
												className='nav__user-img'
												alt={user.name}
											/>
										</Link>
									</li>
									<li>
										<NavLink
											to={'/profile'}
											className='nav-el'
											onClick={() => setShow(false)}
										>
											{user.name?.split(' ')[0]}
										</NavLink>
									</li>
									<li>
										<Link
											to='/profile/balance'
											onClick={() => setShow(false)}
											className='nav-el'
											style={{
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												gap: '.5rem',
											}}
										>
											<span>
												<FontAwesomeIcon icon={faCreditCard} />
											</span>
											<span>
												{' '}
												Balance :{' '}
												<span style={{ color: 'orangered' }}>
													{user?.balance.toFixed(2)}
												</span>
											</span>
										</Link>
									</li>
									{user.isAdmin && (
										<li>
											<NavLink
												to='/dashboard'
												className='nav-el'
												onClick={() => setShow(false)}
											>
												Dashboard
											</NavLink>
										</li>
									)}
								</>
							) : (
								<>
									<li>
										<NavLink
											to='/login'
											className='nav-el'
											onClick={() => setShow(false)}
										>
											Log IN
										</NavLink>
									</li>
									<li>
										<NavLink
											to='/signup'
											className='nav-el signup'
											onClick={() => setShow(false)}
										>
											Sign Up
										</NavLink>
									</li>
								</>
							)}
							<li>
								<Link
									to='/games'
									className='nav-el'
									onClick={() => setShow(false)}
								>
									Games
								</Link>
							</li>
							{user?.isLoggedIn && (
								<li>
									<Link onClick={handleLogout} className='nav-el'>
										{isLoading ? (
											<PulseLoader color='#fafafa' size={10} />
										) : (
											'Logout'
										)}
									</Link>
								</li>
							)}
						</ul>
					</nav>
				</div>
			)}
		</>
	);
};

export default MobileNav;
