import React, { useEffect, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { SubmitButton } from '../../components/form';
import Deposit from './Deposit';
import { useGetMyTransactionsQuery } from './transactionApiSlice';
import TransactionsTable from './TransactionsTable';
import _ from 'lodash';
import Pagination from '../../components/table/Pagination';
import paginate from '../../utils/paginate';
import { useSelector } from 'react-redux';

const Balance = () => {
	const { profile } = useSelector((state) => state.profile);
	const { data, isFetching, isSuccess } = useGetMyTransactionsQuery();
	const [transactions, setTransactions] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortColumn, setSortColumn] = useState({
		path: 'createdAt',
		order: 'desc',
	});

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSort = (sortColumn) => {
		setSortColumn(sortColumn);
	};

	const [showDepositModal, setShowDepositModal] = useState(false);

	const handleShow = () => {
		setShowDepositModal(!showDepositModal);
	};

	useEffect(() => {
		if (isSuccess) {
			setTransactions(data.data.transactions);
		}
	}, [isSuccess, data]);

	// eslint-disable-next-line react-hooks/exhaustive-deps, no-undef
	const getPagedData = (data) => {
		const sorted = _.orderBy(
			transactions,
			[sortColumn.path],
			[sortColumn.order]
		);

		const paginated = paginate(sorted, currentPage, 15);

		return paginated;
	};

	return (
		<>
			<div className='user-view__content'>
				<div className='user-view__form-container'>
					<h2 className='heading-secondary u_margin_bottom_small'>Balance</h2>

					<div>
						<h3 className='heading-tertiary u_margin_bottom_small'>
							Your Available Balance is:{' '}
							<span style={{ color: 'orangered' }}>
								{profile?.balance.toFixed(2)}
							</span>
						</h3>
						<SubmitButton title='Deposit' onClick={handleShow} />
					</div>

					<div className='line'>&nbsp;</div>
					<h3 className='heading-tertiary u_margin_bottom_small'>
						Your Transactions
					</h3>
					{isFetching ? (
						<tr>
							<PulseLoader color={'#55c57a'} />
						</tr>
					) : (
						<TransactionsTable
							transactions={getPagedData()}
							onSort={handleSort}
							sortColumn={sortColumn}
						/>
					)}

					<Pagination
						itemsCount={transactions?.length}
						pageSize={15}
						currentPage={currentPage}
						onPageChange={handlePageChange}
					/>
				</div>

				<div className='line'>&nbsp;</div>
			</div>

			<Deposit show={showDepositModal} onClose={handleShow} />
		</>
	);
};

export default Balance;
