import React from 'react';

const CountryCode = () => {
	return (
		<div className='form__group'>
			<input
				style={{
					marginBottom: '2.9rem',
					padding: '1.5rem 0',
					width: '4rem',
					borderRight: 'black 1px solid',
				}}
				id='country-code'
				disabled
				placeholder='+251'
				type='text'
				className='form__input'
			/>
		</div>
	);
};

export default CountryCode;
