import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SubmitButton } from '../../components/form';
import { ErrorMessage } from '@hookform/error-message';
import { useDepositMutation } from './transactionApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import { useState } from 'react';

const validationSchema = Yup.object().shape({
	email: Yup.string().email('Email not valid').required('Email is required'),
	firstName: Yup.string()
		.min(4, 'First Name must be at least 4 characters')
		.required('First Name is required'),
	lastName: Yup.string()
		.min(4, 'Last Name must be at least 4 characters')
		.required('Last Name is required'),
	amount: Yup.number()
		.min(20, 'Amount must be at least 20')
		.required('Amount is required'),
});

function Deposit({ show, onClose }) {
	const [deposit, { isLoading }] = useDepositMutation();
	const [preSetAmount, setPreSetAmount] = useState(0);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = async (values) => {
		const {
			data: { status, checkout_url },
		} = await deposit(values);
		if (status === 'success') window.location.replace(checkout_url);
	};

	return (
		<>
			{show && (
				<div className='popup'>
					<div className='popup__content'>
						<button
							type='button'
							onClick={() => onClose()}
							className='popup__close'
						>
							&times;
						</button>
						<h2 className='u_center_text  heading-secondary u_margin_top_small u_margin_bottom_small'>
							Deposit
						</h2>
						{isLoading ? (
							<div
								style={{
									height: '50vh',
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								<PulseLoader color={'#55c57a'} />
							</div>
						) : (
							<div>
								<form onSubmit={handleSubmit(onSubmit)} className='row'>
									<div className='col-1-of-3'>
										<div className='form__group'>
											<input
												className='form__input'
												id='firstName'
												type='text'
												placeholder='First Name'
												{...register('firstName')}
											/>
											<label className='form__label' htmlFor='firstName'>
												First Name
											</label>
											<ErrorMessage errors={errors} name='firstName' as='p' />
										</div>
										<div className='form__group'>
											<input
												className='form__input'
												id='lastName'
												type='text'
												placeholder='Last Name'
												{...register('lastName')}
											/>
											<label className='form__label' htmlFor='lastName'>
												Last Name
											</label>
											<ErrorMessage errors={errors} name='lastName' as='p' />
										</div>

										<div className='form__group'>
											<input
												className='form__input'
												id='email'
												type='email'
												placeholder='Email'
												{...register('email')}
											/>
											<label className='form__label' htmlFor='email'>
												Email
											</label>
											<ErrorMessage errors={errors} name='email' as='p' />
										</div>
									</div>

									<div className='row col-2-of-3'>
										<div className='col-1-of-2'>
											<h4 style={{ marginLeft: '1.5rem' }}>
												Select or type in the amount to be deposited
											</h4>
											<div className='row' style={{ marginRight: '1.5rem' }}>
												<div className='col-1-of-3'>
													<button
														onClick={() => {
															setPreSetAmount(20);
														}}
														className='btn btn--blue'
														type='button'
													>
														20
													</button>
													<button
														onClick={() => {
															setPreSetAmount(50);
														}}
														className='btn btn--blue'
														type='button'
													>
														50
													</button>
												</div>

												<div className='col-1-of-3'>
													<button
														onClick={() => {
															setPreSetAmount(100);
														}}
														className='btn btn--blue'
														type='button'
													>
														100
													</button>
													<button
														onClick={() => {
															setPreSetAmount(200);
														}}
														className='btn btn--blue'
														type='button'
													>
														200
													</button>
												</div>

												<div className='col-1-of-3'>
													<button
														onClick={() => {
															setPreSetAmount(500);
														}}
														className='btn btn--blue'
														type='button'
													>
														500
													</button>
													<button
														onClick={() => {
															setPreSetAmount(1000);
														}}
														className='btn btn--blue'
														type='button'
													>
														1000
													</button>
												</div>
											</div>
										</div>
										<div
											style={{ marginTop: '4rem' }}
											className='deposit__amount-input col-1-of-2'
										>
											<div className='form__group'>
												<input
													className='form__input'
													id='amount'
													type='number'
													placeholder='Amount'
													{...register('amount')}
													onChange={(e) => {
														setPreSetAmount(parseInt(e.target.value));
													}}
													value={
														parseInt(preSetAmount) || parseInt(watch('amount'))
													}
												/>
												<label className='form__label' htmlFor='amount'>
													Amount
												</label>
												<ErrorMessage errors={errors} name='amount' as='p' />
											</div>

											<SubmitButton title='Deposit' type='submit' />
										</div>
									</div>
								</form>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

export default Deposit;
