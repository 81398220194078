import { apiSlice } from '../../app/api/apiSlice';

export const transactionApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		deposit: builder.mutation({
			query: (data) => ({
				url: '/transactions/deposit',
				method: 'POST',
				body: { ...data },
			}),
		}),
		getMyTransactions: builder.query({
			query: () => '/transactions/user-transactions',
			providesTags: ['Transactions'],
		}),
	}),
});

// Path: node-game-client/src/features/profile/transactionApiSlice.js
// Compare this snippet from node-game/controllers/transactionsController.js:
//
export const { useDepositMutation, useGetMyTransactionsQuery } =
	transactionApiSlice;
