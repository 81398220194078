import React from 'react';

const MyGames = () => {
	return (
		<div className='user-view__content'>
			<div className='user-view__form-container'>
				<h2 className='heading-secondary ma-bt-md'>Your Game History!</h2>
			</div>
		</div>
	);
};

export default MyGames;
