import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const baseQuery = fetchBaseQuery({
	baseUrl: 'https://api.ethiochewata.com/api',
	credentials: 'include',
	prepareHeaders: (headers) => {
		const token = Cookies.get('accessToken');
		if (token) {
			headers.set('authorization', `Bearer ${token}`);
		} else {
			headers.delete('authorization');
		}
		return headers;
	},
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);

	if (result?.data?.accessToken) {
		Cookies.set('accessToken', result?.data?.accessToken);
	}
	const status = result.meta.response.status;

	if (status === 401 || status === 400 || status === 403) {
		toast.error(result.error.data.message);
	}
	return result;
};

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	tagTypes: ['Users', 'Transactions', 'Games'],
	endpoints: (builder) => ({}),
});
