import React, {useState, useEffect, useRef} from 'react'
import io from 'socket.io-client';
import './rocket.css';

const socket = io.connect('https://number.ethiochewata.com/');


export default function Rocket() {

    const [game, setGame] = useState('');
    const play = useRef(false);
    const [placeBetAmount, setPlaceBetAmount] = useState(0);
    const [timer , setTimer] = useState(0);
    const [cashOutNumber, setCashOutNumber] = useState('');
    const cashArray = useRef([]);


    
    useEffect(() => {
        socket.on('game', (data) => {
            setGame(data)
        })

        socket.on('play', (data) => {
            play.current = data
        })

        socket.on('time', (data) => {
            setTimer(data)
        })
       
    }, [socket])

    useEffect(() => {
        socket.on('possible_cash_out', (data) => {
            cashArray.current = data
        })
    }, [socket])
       


    const playHandler = () => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${'accessToken'}=`);
        const accessToken = parts.pop().split(';').shift();
        socket.emit('join_game', {betAmount: placeBetAmount, accessToken: accessToken})
        socket.on('cash_out_number', (data) => {
            setCashOutNumber(data)
        })
    }

    const userNameHandler = (e) => {
        setPlaceBetAmount(e.target.value)
    }
     

    const cashOutHandler = (e) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${'accessToken'}=`);
        const accessToken = parts.pop().split(';').shift();
        socket.emit('cash_out_request', {betAmount: placeBetAmount, cash_out_number: e.target.id, game_id: game.game_id, time: Date.now(), accessToken: accessToken})
    }


    return (
        <div>
          
            <h2>Hello Rocket</h2>
            <h1>Balance: {game.balance}</h1>
            <h1>Total Balance {game.totalBalance}</h1>
            <h1>#{game.game}</h1>
            {
                game.count_down > 0 ?  <h2>{game.count_down}</h2> : null
            }
            {game.game_id >= 1 ? <h1>Game {game.game_id}</h1> : null}
            <input placeholder='Amount' onChange={userNameHandler}/>
            {play.current.play ? <button onClick={playHandler}>Play</button> : null }  
            {
                cashOutNumber ? <button onClick={cashOutHandler}>CashOut</button> : null
            }

            {
                timer.time > 0 ? <div className='timer'>Timer: {timer.time}</div> : null
            }
           
            {
               timer.time > 0 ? cashArray.current.map((cash, index) => {
                   let cashOut = (cash.betAmount*timer.time).toFixed(2)
                   return <div className='cashout_data'>
                       <h2 className='cashout_num' key={index}>{cashOut}</h2>
                       <button className='cashout_button' id={cash.cashoutId} onClick={cashOutHandler}>Cash out</button>
                    </div>
               }
                ) : null
            }
        </div>
    )
}
